<!-- Start Free Trial Area -->
<section class="free-trial-area pb-100 bg-f4f5fe" style="padding-top: 80px;">
    <div class="container">
        <div class="free-trial-content">
            <h2>Tenha a LIA em sem condomínio</h2>
            <p>Fale agora mesmo com um de nossos representantes</p>
        <a href="https://api.whatsapp.com/send?phone=551137214418&text=Estou%20no%20site%20da%20LIA%20Assistente%20Virtual%20e%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es" class="default-btn"><i class="bx bxs-hot"></i> Quero testar a LIA <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->
