<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Transparent Pricing</h2>
            <p>Border-less account pricing</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Pricing Area -->
<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#">
                    <i class="bx bxs-calendar-check"></i> Monthly
                </a></li>
                <li><a href="#">
                    <i class="bx bxs-calendar-check"></i> Yearly
                </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-pricing-table">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0<sub>/m</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>
                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-pricing-table">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>49<sub>/m</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>
                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div class="single-pricing-table">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79<sub>/m</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 5 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> 200 ChatBot Triggers</li>
                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-pricing-table">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0<sub>/y</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 5 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>
                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-pricing-table">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79<sub>/y</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 6 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> 200 ChatBot Triggers</li>
                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div class="single-pricing-table">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>99<sub>/y</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>
                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>
                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>
                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>
                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>
                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>
                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>Spacle</span></h2>

                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on the free plan?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on a free trial?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Does the price go up as my team gets larger?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I cancel/pause my subscription?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I pay via invoicing?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Our Loving Clients Area -->
<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>

        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients1.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients2.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients3.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients4.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients5.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients6.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients7.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients8.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Our Loving Clients Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Practice active listening and follow through</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->