<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="divider"></div>

    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/white-logo.png" alt="image">
                        </a>
                    </div>
                    <p>Assistente Virtual para Condomínios</p>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">

                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">

                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Informações de Contato</h3>

                    <ul class="footer-contact-info">
                        <li>Telefone: <a href="tel:+551132803232">+55 (11) 3280 3232</a></li>
                        <li><a href="https://lia.seg.br/lgpd">LGPD - POLÍTICA DE PRIVACIDADE</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/assistentelia" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/lia.seg.br" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>&copy; Copyright {{currentYear}} LIA.</p>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-chevron-up'></i></div>
