<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>O futuro chegou para seu condôminio</h1>
                                <p>Utilize a IA para aumentar a performance do seu condôminio.<br>Acesse rapidamente todas as possibilidades dos Leitores Faciais Hikvision</p>
                                <a href="https://api.whatsapp.com/send?phone=551137214418&text=Estou%20no%20site%20da%20LIA%20Assistente%20Virtual%20e%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es" class="default-btn"><i class="bx bxs-hot"></i>Quero testar a LIA<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-image bg-2">
                    <app-chatting></app-chatting>

                    <img src="assets/img/banner-img2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape20"><img src="assets/img/shape/19.png" alt="image"></div>
    <div class="shape21"><img src="assets/img/shape/20.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/23.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/25.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Services Area -->
<section class="services-area bg-right-color ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Controle suas Visitas de forma muito mais inteligente</h2>
                    <p>Com a LIA você pode convidar seus amigos através do whatsapp. Muito fácil. Ela resolve tudo e já adiciona a foto do seu convidado na Leitora Facial</p>
                    <a href="https://api.whatsapp.com/send?phone=551137214418&text=Estou%20no%20site%20da%20LIA%20Assistente%20Virtual%20e%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es" class="default-btn"><i class="bx bxs-spreadsheet"></i> Saber Mais<span></span></a>
                </div>
            </div>

            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/services1.webp" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Services Area -->
<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/services-image/services2.jpg" alt="image">
                </div>
            </div>

            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Auto atendimento e Controle de Encomendas</h2>
                    <p>No recebimento de encomendas, o condômino é avisado pelo whatsapp, facilitando muito o trabalho da portaria e zeladoria.<br>Já os entregadores e visitantes podem realizar o autoatendimento via recepção virtual</p>
                    <a href="https://api.whatsapp.com/send?phone=551137214418&text=Estou%20no%20site%20da%20LIA%20Assistente%20Virtual%20e%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es" class="default-btn"><i class="bx bxs-spreadsheet"></i> Saber Mais<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->


<!-- Start Video Presentation Area -->
<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Assista nosso vídeo demonstrativo para saber mais</h2>
        </div>

        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=JwE_QJ8aXpI" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/6.png" alt="image"></div>
        </div>

        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="20471">00</span><span class="sign-icon"></span></h3>
                        <p>Condôminos</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="34879">00</span><span class="sign-icon"></span></h3>
                        <p>Auto Atendimento</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="130000">00</span><span class="sign-icon"></span></h3>
                        <p>Visitantes</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="417">00</span><span class="sign-icon"></span></h3>
                        <p>Condomínios</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="contact-cta-box">
            <h3>Tem alguma dúvida?</h3>
            <p>Não deixe de nos contatar</p>
            <a href="https://api.whatsapp.com/send?phone=551137214418&text=Estou%20no%20site%20da%20LIA%20Assistente%20Virtual%20e%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es" class="default-btn"><i class="bx bxs-edit-alt"></i>Entrar em Contato<span></span></a>
        </div>

        <div class="contact-cta-box">
            <h3>Aplicativo para Portaria</h3>
            <p>Acesse o download o aplicativo exclusivo<br>para Portarias</p>
            <a href="https://play.google.com/store/apps/details?id=br.seg.lia" target="new" onclick="return confirm('Este aplicativo &eacute; somente para &aacute;rea administrativa, se voc&ecirc; &eacute; um cond&ocirc;mino se cadastre pelo QR Code')" class="default-btn"><i class="bx bxs-edit-alt"></i>Aplicativo Portaria</a>
        </div>
    </div>

    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/9.png" alt="image"></div>
</section>
<!-- End Video Presentation Area -->

<!-- Start Features Area -->
<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>Funcionalidades da LIA</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Auto atendimento</h3>
                    <p>Seus entregadores e visitantes poderão realizar o auto atendimento pelo whatsapp</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-customize'></i>
                    </div>
                    <h3>Aplicativo para Portaria</h3>
                    <p>Aplicativo Próprio para leitura de QRCode e controle por porteiros.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                    <h3>Conversa Intuitiva</h3>
                    <p>A LIA é super fácil, qualquer pessoa consegue utilizá-la</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-reset'></i>
                    </div>
                    <h3>Visitante Único</h3>
                    <p>Libere apenas uma vez seu visitante</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-bell'></i>
                    </div>
                    <h3>Visitante Temporário</h3>
                    <p>Libere um visitante por algum tempo</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                    <h3>Notifique Encomendas</h3>
                    <p>A LIA avisa o condômino quando ele recebe alguma encomenda através do Whatsapp</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Feedback Area -->
<!--<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>O que nossos clientes falam sobre nós</h2>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/woman1.png" alt="image">

                <div class="feedback-desc">
                    <p>Escrever comentário do Cliente 1</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Nome Cliente 1</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/woman2.png" alt="image">

                <div class="feedback-desc">
                    <p>Escrever comentário do Cliente 2</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Nome Cliente 2</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>-->
<!-- Start Feedback Area -->

<!--<app-pricing-plan></app-pricing-plan>-->

<!-- Start FAQ Area -->
<!--<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>Spacle</span></h2>

                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on the free plan?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on a free trial?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Does the price go up as my team gets larger?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I cancel/pause my subscription?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I pay via invoicing?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End FAQ Area -->

<!--<app-clients></app-clients>-->

<app-free-trial></app-free-trial>
